import React from 'react';
import Layout from '../components/layout';
import MainContainer from '../components/container/container';
import { Heading, Text, Box } from 'rebass';

const NotFound = () => {
  return (
    <Layout>
      <MainContainer my={5}>
        <Heading display="block" width="100%">
          Page not found
        </Heading>
        <Text>The page you're looking for does not exist.</Text>
      </MainContainer>
    </Layout>
  );
};

export default NotFound;
